<template>
<div class="founders">
    <el-row>
        <el-col :span="2"></el-col>
        <el-col :span="20">
            <h2 class="founderTitle">Founders message</h2>
            <p class="founderPara">Join us on this exciting journey! Leverage opportunity to drive innovation.</p> 
            <p class="founderPara">Me3 has been created as an evolving project to meet new creative and technical opportunies.</p> 
            <p class="founderPara">It is an experiential media brand and NFT project built to help people <i>live true and live free</i>. In fostering a community of travellers and dreamers, the vision is to provide growth opportunities and create unique, curated experiences through ME3 for holders to explore in Web3 and IRL alike. </p> 
            <p class="founderPara">The project is designed to take advantage of the latest technologies to stimulate fun, creativity and quality of life.</p> 
            <p class="founderPara">With professional experience in idea generation, execution, and marketing, the team envisions ME3 as a brand built through content strategy and scaled through meaningful partnerships that bring communities together.</p> 
            <p class="founderPara">Innovation is a fundamental requirement for building content or technology for the metaverse.</p> 
            <p class="founderPara">Whether you’re a builder or vagabond, Me3 helps you achieve your ambitions and express your true self. </p> 
            <p class="founderPara">With Champion-aesthetic avatars, explore your creativity through our community events, and build out new opportunities in the metaverse alongside our team.</p> 
            <p class="founderPara">As we strive to bring these unique NFT characters to life, we also intend to unearth a surprising revelation about the meaning of life.</p> 
            <p class="founderPara last"><strong>Live true and live free with ME3.</strong></p> 
        </el-col>
        <el-col :span="2"></el-col>
    </el-row>
</div>
</template>

<script>
export default {
    methods: {
            isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style>
.founders
{
    background: white;
}
.founderTitleMobile
{
    font-size: 3vh;
    font-family: "Orbitron", sans-serif !important;
    color: #000000 !important;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    margin-top: 15vh !important;
    font-weight: 700;
    text-transform: uppercase;
}
.founderTitle
{
    font-size: 4vh;
    font-family: "Orbitron", sans-serif !important;
    color: #000000 !important;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    margin-top: 15vh !important;
    font-weight: 700;
    text-transform: uppercase;
}
.founderParaMobile
{
    font-size: 3vh;
    color: #1D1D1DB3;
}
.founderPara
{
    font-size: 2.5vh;
    color: #1D1D1DB3;
}
.last
{
    margin-bottom: 15vh !important;
}
</style>