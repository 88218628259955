<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "Futura";
  src: local("Futura"),
   url('./assets/fonts/FuturaPTBook.ttf') format("truetype");
}
#app {
  font-family: 'Futura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,body
{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: rgb(57, 62, 65);
  scroll-behavior: smooth;
}

</style>
