<template>
<div class="faqs">
    <div>
        <h2 class="termsAndConditions">FAQs</h2>
    </div>
    <el-row v-if="!isMobile()">
        <el-col :span="5"></el-col>
        <el-col :span="14">
            <el-collapse v-model="activeName" >
                <el-collapse-item title="WHAT IS ME3?" name="1">
                    <p class="collapsePara">ME 3 is experiential media brand and innovative NFT collection that inspires self-expression through digital art. As a holder, enjoy exciting utility that spans from metaverse activations to real life experiences.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHICH BLOCKCHAIN NETWORK WILL ME3 BE DEPLOYED ON?" name="2">
                    <p class="collapsePara">ME3 will be deployed on the Ethereum Blockchain with a ERC-721A Contract.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHAT WALLET WOULD YOU RECOMMEND FOR MINTING AND HOLDING?" name="3">
                    <p class="collapsePara">We would recommend using any WalletConnect Compatible wallets to mint.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="HOW DO I MINT A ME3 NFT?" name="4">
                    <p class="collapsePara">Connect your WalletConnect wallet to our minting website on the date of release. Select the number of NFTs you wish to mint and click confirm on the Metamask transaction pop-up in order to secure your NFTs. Minting schedules and more in-depth tutorials will be shared on our Twitter.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHAT IS THE MINT PRICE AND TOTAL SUPPLY?" name="5">
                    <p class="collapsePara">The mint price will be 0.1 for both pre-sale and public mint. Total supply will be 3,030 ME3 NFTs.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHAT PERKS CAN I EXPECT AS AN NFT HOLDER?" name="6">
                    <p class="collapsePara">Holders will enjoy full IP rights to their NFTs and ME3 creative assets, exclusive holder-only merchandise, early access to future NFT drops, integration with metaverse platforms, and VIP experiences at IRL events!</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="HOW CAN I BE REAFFIRMED THAT THIS PROJECT IS IN GOOD HANDS?" name="7">
                    <p class="collapsePara">Rasheed is the founder of ME3. With 10+ years of experience running a private firm in the pharmaceutical industry, Rasheed understands the importance of helping his clients live life to the fullest.</p>
                    <p class="collapsePara">Today, his focus is to continue executing and marketing digital brands effectively to build ME3 from an idea to a full-fledged Web3 brand.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHO OWNS THE I.P?" name="8">
                    <p class="collapsePara">Holders of ME3 enjoy full I.P. rights to their corresponding NFT. When you own the NFT, you can enjoy the right to monetize and build brands featuring your ME3 NFT artwork across technology, art, business, and more.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHAT DIFFERENTIATES ME3 FROM OTHER PROJECTS?" name="9">
                    <p class="collapsePara">ME3 is community-centric and built to innovate creativity.</p>
                    <p class="collapsePara">Our biggest differentiator is our emphasis on delivering new experiences with full-fledged transparency.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="IS THERE A DISCORD?" name="10">
                    <p class="collapsePara">Our Discord is not live yet. Follow our <a href="https://twitter.com/me3collectibles">Twitter</a> for future updates!</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHAT ARE THE FUNDS BEING USED FOR?" name="11">
                    <p class="collapsePara">Mint and secondary proceeds will be reinvested into the project by means of team expansions, development of new utilities and services, execution of community events, and other outlined initiatives.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="IS THERE A FUTURE ROADMAP?" name="12">
                    <p class="collapsePara">Indications of the roadmap will be released  on Twitter. After the full execution of our main roadmap, we will develop expansion roadmaps as needed to reflect long-term updates within the project.</p>
                </el-collapse-item>
            </el-collapse>
        </el-col>
        <el-col :span="5"></el-col>
    </el-row>

    <el-row v-if="isMobile()">
        <el-col :span="24">
            <el-collapse v-model="activeName" >
                <el-collapse-item title="WHAT IS ME3?" name="1">
                    <p class="collapsePara">ME 3 is experiential media brand and innovative NFT collection that inspires self-expression through digital art. As a holder, enjoy exciting utility that spans from metaverse activations to real life experiences.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHERE WILL ME3 LIVE?" name="2">
                    <p class="collapsePara">ME3 will be deployed on the Ethereum Blockchain with a ERC-721A Contract.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHICH WALLET IS RECOMMENDED?" name="3">
                    <p class="collapsePara">We would recommend using any WalletConnect Compatible wallets to mint.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="HOW DO I MINT A ME3 NFT?" name="4">
                    <p class="collapsePara">Connect your WalletConnect wallet to our minting website on the date of release. Select the number of NFTs you wish to mint and click confirm on the Metamask transaction pop-up in order to secure your NFTs. Minting schedules and more in-depth tutorials will be shared on our Twitter.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="MINT PRICE & TOTAL SUPPLY?" name="5">
                    <p class="collapsePara">The mint price will be 0.1 for both pre-sale and public mint. Total supply will be 3,030 ME3 NFTs.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHAT ARE THE PERKS AS A HOLDER?" name="6">
                    <p class="collapsePara">Holders will enjoy full IP rights to their NFTs and ME3 creative assets, exclusive holder-only merchandise, early access to future NFT drops, integration with metaverse platforms, and VIP experiences at IRL events!</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="HOW CAN I BE REAFFIRMED?" name="7">
                    <p class="collapsePara">Rasheed is the founder of ME3. With 10+ years of experience running a private firm in the pharmaceutical industry, Rasheed understands the importance of helping his clients live life to the fullest.</p>
                    <p class="collapsePara">Today, his focus is to continue executing and marketing digital brands effectively to build ME3 from an idea to a full-fledged Web3 brand.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHO OWNS THE I.P?" name="8">
                    <p class="collapsePara">Holders of ME3 enjoy full I.P. rights to their corresponding NFT. When you own the NFT, you can enjoy the right to monetize and build brands featuring your ME3 NFT artwork across technology, art, business, and more.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="WHAT DIFFERENTIATES ME3?" name="9">
                    <p class="collapsePara">ME3 is community-centric and built to innovate creativity.</p>
                    <p class="collapsePara">Our biggest differentiator is our emphasis on delivering new experiences with full-fledged transparency.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="IS THERE A DISCORD?" name="10">
                    <p class="collapsePara">Our Discord is not live yet. Follow our <a href="https://twitter.com/me3collectibles">Twitter</a> for future updates!</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="HOW'RE FUNDS BEING USED?" name="11">
                    <p class="collapsePara">Mint and secondary proceeds will be reinvested into the project by means of team expansions, development of new utilities and services, execution of community events, and other outlined initiatives.</p>
                </el-collapse-item>
                <br><br>
                <el-collapse-item title="IS THERE A FUTURE ROADMAP?" name="12">
                    <p class="collapsePara">Indications of the roadmap will be released  on Twitter. After the full execution of our main roadmap, we will develop expansion roadmaps as needed to reflect long-term updates within the project.</p>
                </el-collapse-item>
            </el-collapse>
        </el-col>
    </el-row>
</div>
</template>

<script>
export default {
    methods: {
        isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
        }
    }
}
</script>

<style>

.faqs
{
    background: transparent;
}
.el-collapse
{
    --el-collapse-header-bg-color: transparent !important;
    --el-collapse-content-bg-color: transparent !important;
    margin-bottom: 0vh;
}
.el-collapse-item__header
{
    font-size: 3vh !important;
    color: white !important;
    font-weight: 700 !important;
}
.el-collapse-item
{
    box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 12%)!important;
    padding: 40px!important;
}
.collapsePara
{
    font-size: 3vh;
    color: white;
    text-align: left;
}
.termsAndConditions
{
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    padding: 60px;
}
</style>