<template>
<div class="teamBG">
    <el-row v-if="!isMobile()">
        <el-col :span="2"></el-col>
        <el-col :span="20" class="teamMargin">
            <div class="teamCard">
                <img class="teamImage" src="../../assets/rasheed.png" alt="">
                <br>
                <h2 class="teamName">RASHEED</h2>
                <h3 class="teamRole">FOUNDER</h3>
                <p class="teamInfo">
                    Rasheed is the founder of ME3. With 10+ years of experience running a private firm in the pharmaceutical industry, Rasheed understands the importance of helping his clients live life to the fullest.<br>Today, his focus is to continue executing and marketing digital brands effectively to build ME3 from an idea to a full-fledged Web3 brand.
                </p>
            </div>

            <div class="teamCard">
                <img class="teamImage" src="../../assets/rq.png" alt="">
                <br>
                <h2 class="teamName">RQ</h2>
                <h3 class="teamRole">DEV</h3>
                <p class="teamInfo">
                    RQ is the dev of ME3, an experiential media brand and NFT project built to help people live true and live free.
                </p>
            </div>
        </el-col>
        <el-col :span="2"></el-col>
    </el-row>

    <el-row v-if="isMobile()">
        <el-col :span="24">
            <h2 class="teamTitle">TEAM</h2>
            <br>
            <div class="teamCardMobile">
                <img class="teamImageMobile" src="../../assets/rasheed.png" alt="">
                <br>
                <h2 class="teamNameMobile">RASHEED</h2>
                <h3 class="teamRoleMobile">FOUNDER</h3>
                <p class="teamInfoMobile">
                    Rasheed is the founder of ME3. With 10+ years of experience running a private firm in the pharmaceutical industry, Rasheed understands the importance of helping his clients live life to the fullest.<br>Today, his focus is to continue executing and marketing digital brands effectively to build ME3 from an idea to a full-fledged Web3 brand.
                </p>
            </div>

            <div class="teamCardMobile">
                <img class="teamImageMobile" src="../../assets/rq.png" alt="">
                <br>
                <h2 class="teamNameMobile">RQ</h2>
                <h3 class="teamRoleMobile">DEV</h3>
                <p class="teamInfoMobile">
                    RQ is the dev of ME3, an experiential media brand and NFT project built to help people live true and live free.
                </p>
            </div>
        </el-col>
    </el-row>
</div>
</template>

<script>
export default {
    methods: {
        isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
        }
    }
}
</script>

<style>
.teamTitle
{
    font-size: 2.75vh;
    font-family: "Orbitron", sans-serif !important;
    color: #000000 !important;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    margin-top: 10vh !important;
    font-weight: 700;
    text-transform: uppercase;
}
.teamCardMobile
{
    width: 30%;
    margin: 0;
    margin-left: 2vh;
    margin-right: 2vh;
    margin-top: 5vh;
    margin-bottom: 10vh;
    display: inline-block;
}
.teamImageMobile
{
    width: 100%;
}
.teamBG
{
    background: rgb(246, 246, 246);
}
.teamMargin
{
    margin-top: 5vw;
    margin-bottom: 5vw;
}
.teamCard
{
    display: inline-block;
    margin-left: 2vw;
    margin-right: 2vw;
    width: 15vw;
}
.teamName
{
    font-weight: 700;
    letter-spacing: 0.3vw;
}
.teamRole
{
    opacity: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}
.teamInfo
{
    opacity: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}
.teamImage
{
    border-radius: 999px;
    width: 100%;
    transition: all .2s ease-in-out;
}
.teamImage:hover
{
    transform: scale(1.1);
    opacity: 1;
}
.teamImage:hover ~ .teamRole
{
    opacity: 1 !important;
}
.teamImage:hover ~ .teamInfo
{
    opacity: 1 !important;
}
</style>