<template>
<div class="footer" v-if="!isMobile()">
    <el-row class="rowFooter">

        <el-col :span="8" class="footerTextMargin">
            <h2 class="whiteText footerText lighterText">WE ARE A LABOR OF LOVE FROM<br><br>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="footerLogo" x="0px" y="0px" style="enable-background:new 0 0 2000 2000;" xml:space="preserve" viewBox="392.06 763.76 1215.88 472.48">
            <g>
                <polyline points="774.33,763.76 583.19,908.48 392.06,763.76 393.15,1160.78 521.87,1160.78 521.87,1083.29 538.41,1083.29    538.41,1160.78 629.07,1160.78 629.07,1083.29 645.61,1083.29 645.61,1160.78 775.42,1160.78 774.33,763.76  "/>
                <polyline points="1117.92,763.76 788.97,763.76 788.97,1160.78 1117.92,1160.78 1117.92,1026.93 1035.69,1026.93 1035.69,1009.91    1117.92,1009.91 1117.92,914.63 1035.69,914.63 1035.69,897.61 1117.92,897.61 1117.92,763.76  "/>
                <path d="M1460.21,763.76h-206.45v133.85h80.54v17.02h-80.54v95.28h80.54v17.02h-80.54v133.85h200.21   c140.66,0,201.91-145.76,111.17-212.69C1630.93,875.49,1568.54,763.76,1460.21,763.76"/>
                <polyline points="608.45,1201.74 603.28,1201.74 603.28,1236.24 637.73,1236.24 637.73,1231.07 608.45,1231.07 608.45,1201.74  "/>
                <polyline points="654.34,1201.79 649.17,1201.79 649.17,1236.24 654.34,1236.24 654.34,1201.79  "/>
                <polyline points="712.18,1201.79 706.25,1201.79 689.74,1230.4 673.24,1201.79 667.26,1201.79 687.16,1236.24 692.33,1236.24    712.18,1201.79  "/>
                <polyline points="756.54,1201.79 724.96,1201.79 724.96,1236.24 756.54,1236.24 756.54,1231.07 730.13,1231.07 730.13,1221.6    751.37,1221.6 751.37,1216.43 730.13,1216.43 730.13,1206.95 756.54,1206.95 756.54,1201.79  "/>
                <polyline points="825.34,1201.79 790.89,1201.79 790.89,1206.95 805.53,1206.95 805.53,1236.24 810.7,1236.24 810.7,1206.95    825.34,1206.95 825.34,1201.79  "/>
                <path d="M843.71,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H843.71 M865.72,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C868.91,1202.45,867.44,1201.83,865.72,1201.83"/>
                <path d="M919.98,1201.79h-5.17v28.23c0,0.29-0.1,0.53-0.31,0.74c-0.21,0.21-0.45,0.31-0.74,0.31h-22.01c-0.29,0-0.53-0.1-0.74-0.31   c-0.21-0.21-0.31-0.45-0.31-0.74v-28.23h-5.17v28.23c0,1.72,0.61,3.19,1.82,4.4c1.21,1.21,2.68,1.82,4.4,1.82h22.01   c1.72,0,3.19-0.61,4.4-1.82c1.21-1.21,1.82-2.68,1.82-4.4V1201.79"/>
                <polyline points="965.53,1201.79 933.95,1201.79 933.95,1236.24 965.53,1236.24 965.53,1231.07 939.12,1231.07 939.12,1221.6    960.36,1221.6 960.36,1216.43 939.12,1216.43 939.12,1206.95 965.53,1206.95 965.53,1201.79  "/>
                <polyline points="984.19,1231.07 979.02,1231.07 979.02,1236.24 984.19,1236.24 984.19,1231.07  "/>
                <polyline points="1025.67,1201.74 1020.5,1201.74 1020.5,1236.24 1054.95,1236.24 1054.95,1231.07 1025.67,1231.07    1025.67,1201.74  "/>
                <polyline points="1071.55,1201.79 1066.39,1201.79 1066.39,1236.24 1071.55,1236.24 1071.55,1201.79  "/>
                <polyline points="1129.4,1201.79 1123.47,1201.79 1106.96,1230.4 1090.45,1201.79 1084.47,1201.79 1104.38,1236.24    1109.54,1236.24 1129.4,1201.79  "/>
                <polyline points="1173.75,1201.79 1142.17,1201.79 1142.17,1236.24 1173.75,1236.24 1173.75,1231.07 1147.34,1231.07    1147.34,1221.6 1168.59,1221.6 1168.59,1216.43 1147.34,1216.43 1147.34,1206.95 1173.75,1206.95 1173.75,1201.79  "/>
                <polyline points="1241.5,1201.79 1209.92,1201.79 1209.92,1236.24 1215.09,1236.24 1215.09,1221.6 1236.34,1221.6 1236.34,1216.43    1215.09,1216.43 1215.09,1206.95 1241.5,1206.95 1241.5,1201.79  "/>
                <path d="M1259.21,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H1259.21 M1281.21,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C1284.4,1202.45,1282.94,1201.83,1281.21,1201.83"/>
                <polyline points="1332.79,1201.79 1301.21,1201.79 1301.21,1236.24 1332.79,1236.24 1332.79,1231.07 1306.38,1231.07    1306.38,1221.6 1327.63,1221.6 1327.63,1216.43 1306.38,1216.43 1306.38,1206.95 1332.79,1206.95 1332.79,1201.79  "/>
                <polyline points="1378.06,1201.79 1346.48,1201.79 1346.48,1236.24 1378.06,1236.24 1378.06,1231.07 1351.64,1231.07    1351.64,1221.6 1372.89,1221.6 1372.89,1216.43 1351.64,1216.43 1351.64,1206.95 1378.06,1206.95 1378.06,1201.79  "/>
                <polyline points="1396.72,1231.07 1391.55,1231.07 1391.55,1236.24 1396.72,1236.24 1396.72,1231.07  "/>
            </g>
            </svg>
            </h2>
        </el-col>
        
        <el-col :span="4">

        </el-col>

        <el-col :span="4">
            <h3 class="whiteText">SOCIALS</h3>
<!--             <svg id="socialLinkFooter" viewBox="0 0 71.9 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M60.5 4.9C56 2.9 51.2 1.3 46.1.4c-.1 0-.2 0-.2.1-.6 1.1-1.3 2.6-1.8 3.7-5.5-.8-10.9-.8-16.2 0-.5-1.2-1.2-2.6-1.8-3.7 0-.1-.1-.1-.2-.1-5.1.9-9.9 2.4-14.5 4.5 0 0-.1 0-.1.1C2 18.8-.5 32.2.7 45.4c0 .1 0 .1.1.2 6.1 4.5 12 7.2 17.7 9 .1 0 .2 0 .2-.1 1.4-1.9 2.6-3.8 3.6-5.9.1-.1 0-.3-.1-.3-1.9-.7-3.8-1.6-5.5-2.6-.1-.1-.2-.3 0-.4.4-.3.7-.6 1.1-.9.1-.1.2-.1.2 0 11.6 5.3 24.2 5.3 35.7 0h.2c.4.3.7.6 1.1.9.1.1.1.3 0 .4-1.8 1-3.6 1.9-5.5 2.6-.1 0-.2.2-.1.3 1.1 2.1 2.3 4 3.6 5.9.1.1.2.1.2.1 5.8-1.8 11.7-4.5 17.8-9 .1 0 .1-.1.1-.2 1.5-15.3-2.4-28.6-10.6-40.5.1.1.1 0 0 0zM24.2 37.4c-3.5 0-6.4-3.2-6.4-7.2s2.8-7.2 6.4-7.2c3.6 0 6.4 3.2 6.4 7.2 0 3.9-2.9 7.2-6.4 7.2zm23.6 0c-3.5 0-6.4-3.2-6.4-7.2s2.8-7.2 6.4-7.2c3.6 0 6.4 3.2 6.4 7.2-.1 3.9-2.9 7.2-6.4 7.2z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"></path>
            </svg> -->
            <svg @click="routeTo()" id="socialLinkFooter2" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.2973 19.9038C6.40716 19.9038 4.61557 19.5097 3.00371 18.8066C4.99881 18.6662 6.82652 17.9535 8.31433 16.8374C8.46274 16.7261 8.52405 16.5328 8.46692 16.3563C8.40978 16.1798 8.24683 16.059 8.06134 16.0558C6.47058 16.0279 5.10119 15.1591 4.40825 13.9088C4.46717 13.9109 4.52647 13.9119 4.58619 13.9119C5.0466 13.9119 5.49577 13.852 5.91936 13.7427C6.11608 13.6919 6.25131 13.5115 6.2449 13.3085C6.23849 13.1054 6.09216 12.9339 5.89263 12.8956C4.16848 12.565 2.84028 11.2366 2.56591 9.58884C3.06998 9.76905 3.61107 9.87526 4.17407 9.89203C4.36936 9.89785 4.54449 9.77254 4.60203 9.58583C4.65957 9.39912 4.58536 9.19698 4.42067 9.09185C3.28763 8.36862 2.55092 7.13802 2.55092 5.7524C2.55092 5.23123 2.65517 4.73347 2.84468 4.27632C5.20286 6.79039 8.55859 8.42333 12.3125 8.60257C12.4497 8.60912 12.582 8.55032 12.6691 8.44402C12.7562 8.33772 12.7878 8.19648 12.7544 8.06317C12.6824 7.77571 12.6457 7.47119 12.6457 7.15493C12.6457 4.9539 14.5172 3.13547 16.863 3.13547C18.0843 3.13547 19.1809 3.62831 19.9496 4.4112C20.0512 4.51473 20.1978 4.56066 20.3404 4.53363C20.9964 4.4092 21.6292 4.22278 22.2321 3.97991C21.8948 4.436 21.4591 4.82347 20.9535 5.11324C20.7748 5.21567 20.6926 5.42958 20.7568 5.62533C20.821 5.82108 21.0138 5.94481 21.2185 5.92155C21.6628 5.87106 22.0977 5.79217 22.5218 5.68677C22.1402 6.07113 21.7214 6.42185 21.2711 6.73278C21.147 6.8185 21.0762 6.96252 21.0842 7.11318C21.0938 7.29424 21.0972 7.47722 21.0972 7.66671C21.0972 13.3468 16.5798 19.9038 8.2973 19.9038Z" stroke="currentColor" stroke-width="0.868589" stroke-linejoin="round"></path>
            </svg>
        </el-col>

        <el-col :span="4">
            <h3 class="whiteText">NFT CALENDARS</h3>
            <h4 class="whiteText lighterText">ICY TOOLS</h4>
            <h4 class="whiteText lighterText">NFT DROPS</h4>
        </el-col>

        <el-col :span="4">

        </el-col>

    </el-row>
</div>

<div v-if="isMobile()">

    <el-row>
        <el-col :span="24">
            <h2 class="whiteText footerText lighterText">WE ARE A LABOR OF LOVE FROM<br><br>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="footerLogo" x="0px" y="0px" style="enable-background:new 0 0 2000 2000;" xml:space="preserve" viewBox="392.06 763.76 1215.88 472.48">
            <g>
                <polyline points="774.33,763.76 583.19,908.48 392.06,763.76 393.15,1160.78 521.87,1160.78 521.87,1083.29 538.41,1083.29    538.41,1160.78 629.07,1160.78 629.07,1083.29 645.61,1083.29 645.61,1160.78 775.42,1160.78 774.33,763.76  "/>
                <polyline points="1117.92,763.76 788.97,763.76 788.97,1160.78 1117.92,1160.78 1117.92,1026.93 1035.69,1026.93 1035.69,1009.91    1117.92,1009.91 1117.92,914.63 1035.69,914.63 1035.69,897.61 1117.92,897.61 1117.92,763.76  "/>
                <path d="M1460.21,763.76h-206.45v133.85h80.54v17.02h-80.54v95.28h80.54v17.02h-80.54v133.85h200.21   c140.66,0,201.91-145.76,111.17-212.69C1630.93,875.49,1568.54,763.76,1460.21,763.76"/>
                <polyline points="608.45,1201.74 603.28,1201.74 603.28,1236.24 637.73,1236.24 637.73,1231.07 608.45,1231.07 608.45,1201.74  "/>
                <polyline points="654.34,1201.79 649.17,1201.79 649.17,1236.24 654.34,1236.24 654.34,1201.79  "/>
                <polyline points="712.18,1201.79 706.25,1201.79 689.74,1230.4 673.24,1201.79 667.26,1201.79 687.16,1236.24 692.33,1236.24    712.18,1201.79  "/>
                <polyline points="756.54,1201.79 724.96,1201.79 724.96,1236.24 756.54,1236.24 756.54,1231.07 730.13,1231.07 730.13,1221.6    751.37,1221.6 751.37,1216.43 730.13,1216.43 730.13,1206.95 756.54,1206.95 756.54,1201.79  "/>
                <polyline points="825.34,1201.79 790.89,1201.79 790.89,1206.95 805.53,1206.95 805.53,1236.24 810.7,1236.24 810.7,1206.95    825.34,1206.95 825.34,1201.79  "/>
                <path d="M843.71,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H843.71 M865.72,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C868.91,1202.45,867.44,1201.83,865.72,1201.83"/>
                <path d="M919.98,1201.79h-5.17v28.23c0,0.29-0.1,0.53-0.31,0.74c-0.21,0.21-0.45,0.31-0.74,0.31h-22.01c-0.29,0-0.53-0.1-0.74-0.31   c-0.21-0.21-0.31-0.45-0.31-0.74v-28.23h-5.17v28.23c0,1.72,0.61,3.19,1.82,4.4c1.21,1.21,2.68,1.82,4.4,1.82h22.01   c1.72,0,3.19-0.61,4.4-1.82c1.21-1.21,1.82-2.68,1.82-4.4V1201.79"/>
                <polyline points="965.53,1201.79 933.95,1201.79 933.95,1236.24 965.53,1236.24 965.53,1231.07 939.12,1231.07 939.12,1221.6    960.36,1221.6 960.36,1216.43 939.12,1216.43 939.12,1206.95 965.53,1206.95 965.53,1201.79  "/>
                <polyline points="984.19,1231.07 979.02,1231.07 979.02,1236.24 984.19,1236.24 984.19,1231.07  "/>
                <polyline points="1025.67,1201.74 1020.5,1201.74 1020.5,1236.24 1054.95,1236.24 1054.95,1231.07 1025.67,1231.07    1025.67,1201.74  "/>
                <polyline points="1071.55,1201.79 1066.39,1201.79 1066.39,1236.24 1071.55,1236.24 1071.55,1201.79  "/>
                <polyline points="1129.4,1201.79 1123.47,1201.79 1106.96,1230.4 1090.45,1201.79 1084.47,1201.79 1104.38,1236.24    1109.54,1236.24 1129.4,1201.79  "/>
                <polyline points="1173.75,1201.79 1142.17,1201.79 1142.17,1236.24 1173.75,1236.24 1173.75,1231.07 1147.34,1231.07    1147.34,1221.6 1168.59,1221.6 1168.59,1216.43 1147.34,1216.43 1147.34,1206.95 1173.75,1206.95 1173.75,1201.79  "/>
                <polyline points="1241.5,1201.79 1209.92,1201.79 1209.92,1236.24 1215.09,1236.24 1215.09,1221.6 1236.34,1221.6 1236.34,1216.43    1215.09,1216.43 1215.09,1206.95 1241.5,1206.95 1241.5,1201.79  "/>
                <path d="M1259.21,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H1259.21 M1281.21,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C1284.4,1202.45,1282.94,1201.83,1281.21,1201.83"/>
                <polyline points="1332.79,1201.79 1301.21,1201.79 1301.21,1236.24 1332.79,1236.24 1332.79,1231.07 1306.38,1231.07    1306.38,1221.6 1327.63,1221.6 1327.63,1216.43 1306.38,1216.43 1306.38,1206.95 1332.79,1206.95 1332.79,1201.79  "/>
                <polyline points="1378.06,1201.79 1346.48,1201.79 1346.48,1236.24 1378.06,1236.24 1378.06,1231.07 1351.64,1231.07    1351.64,1221.6 1372.89,1221.6 1372.89,1216.43 1351.64,1216.43 1351.64,1206.95 1378.06,1206.95 1378.06,1201.79  "/>
                <polyline points="1396.72,1231.07 1391.55,1231.07 1391.55,1236.24 1396.72,1236.24 1396.72,1231.07  "/>
            </g>
            </svg>
            </h2>
        </el-col>
    </el-row>
    <hr>
    <el-row style="background-color: rgb(57, 62, 65);">
        <el-col :span="12">
            <h3 class="whiteText">NFT CALENDARS</h3>
            <h4 class="whiteText lighterText">ICY TOOLS</h4>
            <h4 class="whiteText lighterText">NFT DROPS</h4>
        </el-col>

        <el-col :span="12">
            <h3 class="whiteText">SOCIALS</h3>
<!--             <svg id="socialLinkFooter" viewBox="0 0 71.9 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M60.5 4.9C56 2.9 51.2 1.3 46.1.4c-.1 0-.2 0-.2.1-.6 1.1-1.3 2.6-1.8 3.7-5.5-.8-10.9-.8-16.2 0-.5-1.2-1.2-2.6-1.8-3.7 0-.1-.1-.1-.2-.1-5.1.9-9.9 2.4-14.5 4.5 0 0-.1 0-.1.1C2 18.8-.5 32.2.7 45.4c0 .1 0 .1.1.2 6.1 4.5 12 7.2 17.7 9 .1 0 .2 0 .2-.1 1.4-1.9 2.6-3.8 3.6-5.9.1-.1 0-.3-.1-.3-1.9-.7-3.8-1.6-5.5-2.6-.1-.1-.2-.3 0-.4.4-.3.7-.6 1.1-.9.1-.1.2-.1.2 0 11.6 5.3 24.2 5.3 35.7 0h.2c.4.3.7.6 1.1.9.1.1.1.3 0 .4-1.8 1-3.6 1.9-5.5 2.6-.1 0-.2.2-.1.3 1.1 2.1 2.3 4 3.6 5.9.1.1.2.1.2.1 5.8-1.8 11.7-4.5 17.8-9 .1 0 .1-.1.1-.2 1.5-15.3-2.4-28.6-10.6-40.5.1.1.1 0 0 0zM24.2 37.4c-3.5 0-6.4-3.2-6.4-7.2s2.8-7.2 6.4-7.2c3.6 0 6.4 3.2 6.4 7.2 0 3.9-2.9 7.2-6.4 7.2zm23.6 0c-3.5 0-6.4-3.2-6.4-7.2s2.8-7.2 6.4-7.2c3.6 0 6.4 3.2 6.4 7.2-.1 3.9-2.9 7.2-6.4 7.2z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"></path>
            </svg> -->
            <svg @click="routeTo()" id="socialLinkFooter2" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.2973 19.9038C6.40716 19.9038 4.61557 19.5097 3.00371 18.8066C4.99881 18.6662 6.82652 17.9535 8.31433 16.8374C8.46274 16.7261 8.52405 16.5328 8.46692 16.3563C8.40978 16.1798 8.24683 16.059 8.06134 16.0558C6.47058 16.0279 5.10119 15.1591 4.40825 13.9088C4.46717 13.9109 4.52647 13.9119 4.58619 13.9119C5.0466 13.9119 5.49577 13.852 5.91936 13.7427C6.11608 13.6919 6.25131 13.5115 6.2449 13.3085C6.23849 13.1054 6.09216 12.9339 5.89263 12.8956C4.16848 12.565 2.84028 11.2366 2.56591 9.58884C3.06998 9.76905 3.61107 9.87526 4.17407 9.89203C4.36936 9.89785 4.54449 9.77254 4.60203 9.58583C4.65957 9.39912 4.58536 9.19698 4.42067 9.09185C3.28763 8.36862 2.55092 7.13802 2.55092 5.7524C2.55092 5.23123 2.65517 4.73347 2.84468 4.27632C5.20286 6.79039 8.55859 8.42333 12.3125 8.60257C12.4497 8.60912 12.582 8.55032 12.6691 8.44402C12.7562 8.33772 12.7878 8.19648 12.7544 8.06317C12.6824 7.77571 12.6457 7.47119 12.6457 7.15493C12.6457 4.9539 14.5172 3.13547 16.863 3.13547C18.0843 3.13547 19.1809 3.62831 19.9496 4.4112C20.0512 4.51473 20.1978 4.56066 20.3404 4.53363C20.9964 4.4092 21.6292 4.22278 22.2321 3.97991C21.8948 4.436 21.4591 4.82347 20.9535 5.11324C20.7748 5.21567 20.6926 5.42958 20.7568 5.62533C20.821 5.82108 21.0138 5.94481 21.2185 5.92155C21.6628 5.87106 22.0977 5.79217 22.5218 5.68677C22.1402 6.07113 21.7214 6.42185 21.2711 6.73278C21.147 6.8185 21.0762 6.96252 21.0842 7.11318C21.0938 7.29424 21.0972 7.47722 21.0972 7.66671C21.0972 13.3468 16.5798 19.9038 8.2973 19.9038Z" stroke="currentColor" stroke-width="0.868589" stroke-linejoin="round"></path>
            </svg>
        </el-col>

    </el-row>
</div>
</template>

<script>
export default {
    methods: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        routeTo()
        {
            window.open("https://twitter.com/me3collectibles");
        }
    }
}
</script>

<style>
.footer
{
    background-color: rgb(57, 62, 65);
}
#socialLinkFooter, #socialLinkFooter2
{
    width: 5vh;
    color: white;
    display: inline-flex;
    margin-left: 1vh;
    margin-right: 1vh;
    cursor: pointer;
    vertical-align: middle;
    z-index: 999;
}
.footerText
{
    margin-top: 1vw;
    margin-bottom: 1.4vw;
}
.rowFooter
{
    margin-top: 4vw;
}
.whiteText
{
    color: white;
    font-weight: 700;
}
.lighterText
{
    font-weight: 400 !important;
}
.footerLogo
{
    width: 15vh;
    filter: invert(100%) sepia(1%) saturate(7472%) hue-rotate(117deg) brightness(109%) contrast(100%)
}
</style>