<template>
<div>
  <img v-if="!isMobile()" class="posterImage" src="../../assets/poster.png" alt="">
  <img v-if="isMobile()" class="posterImageMobile" src="../../assets/poster.png" alt="">
</div>
</template>

<script>
export default {
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
.posterImage
{
  width: 100%;
  margin-top: 3vw;
}
.posterImageMobile
{
  width: 100%;
  margin-top: 10vw;
}
</style>