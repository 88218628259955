<template>
<div>
  <Navbar/>
  <Hero/>
  <DetailBar/>
  <Founders/>
  <Team/>
  <FAQ/>
  <Footer/>
</div>
</template>

<script>
import Navbar from './Navigation/Navbar.vue'
import Hero from './HomeComps/Hero.vue'
import DetailBar from './HomeComps/DetailBar.vue'
import Founders from './HomeComps/Founders.vue'
import Team from './HomeComps/Team.vue'
import Footer from './Navigation/Footer.vue'
import FAQ from './HomeComps/FAQ.vue'

export default {
  components:{
    Navbar,
    Hero,
    DetailBar,
    Founders,
    FAQ,
    Team,
    Footer
  },
}
</script>

<style>

</style>