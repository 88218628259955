<template>
<div class="detailBar">
    <el-row>
        <el-col :span="7"></el-col>
        <el-col :span="10">
        <svg v-if="!isMobile()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="logo" x="0px" y="0px" style="enable-background:new 0 0 2000 2000;" xml:space="preserve" viewBox="392.06 763.76 1215.88 472.48">
        <g>
            <polyline points="774.33,763.76 583.19,908.48 392.06,763.76 393.15,1160.78 521.87,1160.78 521.87,1083.29 538.41,1083.29    538.41,1160.78 629.07,1160.78 629.07,1083.29 645.61,1083.29 645.61,1160.78 775.42,1160.78 774.33,763.76  "/>
            <polyline points="1117.92,763.76 788.97,763.76 788.97,1160.78 1117.92,1160.78 1117.92,1026.93 1035.69,1026.93 1035.69,1009.91    1117.92,1009.91 1117.92,914.63 1035.69,914.63 1035.69,897.61 1117.92,897.61 1117.92,763.76  "/>
            <path d="M1460.21,763.76h-206.45v133.85h80.54v17.02h-80.54v95.28h80.54v17.02h-80.54v133.85h200.21   c140.66,0,201.91-145.76,111.17-212.69C1630.93,875.49,1568.54,763.76,1460.21,763.76"/>
            <polyline points="608.45,1201.74 603.28,1201.74 603.28,1236.24 637.73,1236.24 637.73,1231.07 608.45,1231.07 608.45,1201.74  "/>
            <polyline points="654.34,1201.79 649.17,1201.79 649.17,1236.24 654.34,1236.24 654.34,1201.79  "/>
            <polyline points="712.18,1201.79 706.25,1201.79 689.74,1230.4 673.24,1201.79 667.26,1201.79 687.16,1236.24 692.33,1236.24    712.18,1201.79  "/>
            <polyline points="756.54,1201.79 724.96,1201.79 724.96,1236.24 756.54,1236.24 756.54,1231.07 730.13,1231.07 730.13,1221.6    751.37,1221.6 751.37,1216.43 730.13,1216.43 730.13,1206.95 756.54,1206.95 756.54,1201.79  "/>
            <polyline points="825.34,1201.79 790.89,1201.79 790.89,1206.95 805.53,1206.95 805.53,1236.24 810.7,1236.24 810.7,1206.95    825.34,1206.95 825.34,1201.79  "/>
            <path d="M843.71,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H843.71 M865.72,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C868.91,1202.45,867.44,1201.83,865.72,1201.83"/>
            <path d="M919.98,1201.79h-5.17v28.23c0,0.29-0.1,0.53-0.31,0.74c-0.21,0.21-0.45,0.31-0.74,0.31h-22.01c-0.29,0-0.53-0.1-0.74-0.31   c-0.21-0.21-0.31-0.45-0.31-0.74v-28.23h-5.17v28.23c0,1.72,0.61,3.19,1.82,4.4c1.21,1.21,2.68,1.82,4.4,1.82h22.01   c1.72,0,3.19-0.61,4.4-1.82c1.21-1.21,1.82-2.68,1.82-4.4V1201.79"/>
            <polyline points="965.53,1201.79 933.95,1201.79 933.95,1236.24 965.53,1236.24 965.53,1231.07 939.12,1231.07 939.12,1221.6    960.36,1221.6 960.36,1216.43 939.12,1216.43 939.12,1206.95 965.53,1206.95 965.53,1201.79  "/>
            <polyline points="984.19,1231.07 979.02,1231.07 979.02,1236.24 984.19,1236.24 984.19,1231.07  "/>
            <polyline points="1025.67,1201.74 1020.5,1201.74 1020.5,1236.24 1054.95,1236.24 1054.95,1231.07 1025.67,1231.07    1025.67,1201.74  "/>
            <polyline points="1071.55,1201.79 1066.39,1201.79 1066.39,1236.24 1071.55,1236.24 1071.55,1201.79  "/>
            <polyline points="1129.4,1201.79 1123.47,1201.79 1106.96,1230.4 1090.45,1201.79 1084.47,1201.79 1104.38,1236.24    1109.54,1236.24 1129.4,1201.79  "/>
            <polyline points="1173.75,1201.79 1142.17,1201.79 1142.17,1236.24 1173.75,1236.24 1173.75,1231.07 1147.34,1231.07    1147.34,1221.6 1168.59,1221.6 1168.59,1216.43 1147.34,1216.43 1147.34,1206.95 1173.75,1206.95 1173.75,1201.79  "/>
            <polyline points="1241.5,1201.79 1209.92,1201.79 1209.92,1236.24 1215.09,1236.24 1215.09,1221.6 1236.34,1221.6 1236.34,1216.43    1215.09,1216.43 1215.09,1206.95 1241.5,1206.95 1241.5,1201.79  "/>
            <path d="M1259.21,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H1259.21 M1281.21,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C1284.4,1202.45,1282.94,1201.83,1281.21,1201.83"/>
            <polyline points="1332.79,1201.79 1301.21,1201.79 1301.21,1236.24 1332.79,1236.24 1332.79,1231.07 1306.38,1231.07    1306.38,1221.6 1327.63,1221.6 1327.63,1216.43 1306.38,1216.43 1306.38,1206.95 1332.79,1206.95 1332.79,1201.79  "/>
            <polyline points="1378.06,1201.79 1346.48,1201.79 1346.48,1236.24 1378.06,1236.24 1378.06,1231.07 1351.64,1231.07    1351.64,1221.6 1372.89,1221.6 1372.89,1216.43 1351.64,1216.43 1351.64,1206.95 1378.06,1206.95 1378.06,1201.79  "/>
            <polyline points="1396.72,1231.07 1391.55,1231.07 1391.55,1236.24 1396.72,1236.24 1396.72,1231.07  "/>
        </g>
        </svg>
        <svg v-if="isMobile()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" class="logoMobile" x="0px" y="0px" style="enable-background:new 0 0 2000 2000;" xml:space="preserve" viewBox="392.06 763.76 1215.88 472.48">
        <g>
            <polyline points="774.33,763.76 583.19,908.48 392.06,763.76 393.15,1160.78 521.87,1160.78 521.87,1083.29 538.41,1083.29    538.41,1160.78 629.07,1160.78 629.07,1083.29 645.61,1083.29 645.61,1160.78 775.42,1160.78 774.33,763.76  "/>
            <polyline points="1117.92,763.76 788.97,763.76 788.97,1160.78 1117.92,1160.78 1117.92,1026.93 1035.69,1026.93 1035.69,1009.91    1117.92,1009.91 1117.92,914.63 1035.69,914.63 1035.69,897.61 1117.92,897.61 1117.92,763.76  "/>
            <path d="M1460.21,763.76h-206.45v133.85h80.54v17.02h-80.54v95.28h80.54v17.02h-80.54v133.85h200.21   c140.66,0,201.91-145.76,111.17-212.69C1630.93,875.49,1568.54,763.76,1460.21,763.76"/>
            <polyline points="608.45,1201.74 603.28,1201.74 603.28,1236.24 637.73,1236.24 637.73,1231.07 608.45,1231.07 608.45,1201.74  "/>
            <polyline points="654.34,1201.79 649.17,1201.79 649.17,1236.24 654.34,1236.24 654.34,1201.79  "/>
            <polyline points="712.18,1201.79 706.25,1201.79 689.74,1230.4 673.24,1201.79 667.26,1201.79 687.16,1236.24 692.33,1236.24    712.18,1201.79  "/>
            <polyline points="756.54,1201.79 724.96,1201.79 724.96,1236.24 756.54,1236.24 756.54,1231.07 730.13,1231.07 730.13,1221.6    751.37,1221.6 751.37,1216.43 730.13,1216.43 730.13,1206.95 756.54,1206.95 756.54,1201.79  "/>
            <polyline points="825.34,1201.79 790.89,1201.79 790.89,1206.95 805.53,1206.95 805.53,1236.24 810.7,1236.24 810.7,1206.95    825.34,1206.95 825.34,1201.79  "/>
            <path d="M843.71,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H843.71 M865.72,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C868.91,1202.45,867.44,1201.83,865.72,1201.83"/>
            <path d="M919.98,1201.79h-5.17v28.23c0,0.29-0.1,0.53-0.31,0.74c-0.21,0.21-0.45,0.31-0.74,0.31h-22.01c-0.29,0-0.53-0.1-0.74-0.31   c-0.21-0.21-0.31-0.45-0.31-0.74v-28.23h-5.17v28.23c0,1.72,0.61,3.19,1.82,4.4c1.21,1.21,2.68,1.82,4.4,1.82h22.01   c1.72,0,3.19-0.61,4.4-1.82c1.21-1.21,1.82-2.68,1.82-4.4V1201.79"/>
            <polyline points="965.53,1201.79 933.95,1201.79 933.95,1236.24 965.53,1236.24 965.53,1231.07 939.12,1231.07 939.12,1221.6    960.36,1221.6 960.36,1216.43 939.12,1216.43 939.12,1206.95 965.53,1206.95 965.53,1201.79  "/>
            <polyline points="984.19,1231.07 979.02,1231.07 979.02,1236.24 984.19,1236.24 984.19,1231.07  "/>
            <polyline points="1025.67,1201.74 1020.5,1201.74 1020.5,1236.24 1054.95,1236.24 1054.95,1231.07 1025.67,1231.07    1025.67,1201.74  "/>
            <polyline points="1071.55,1201.79 1066.39,1201.79 1066.39,1236.24 1071.55,1236.24 1071.55,1201.79  "/>
            <polyline points="1129.4,1201.79 1123.47,1201.79 1106.96,1230.4 1090.45,1201.79 1084.47,1201.79 1104.38,1236.24    1109.54,1236.24 1129.4,1201.79  "/>
            <polyline points="1173.75,1201.79 1142.17,1201.79 1142.17,1236.24 1173.75,1236.24 1173.75,1231.07 1147.34,1231.07    1147.34,1221.6 1168.59,1221.6 1168.59,1216.43 1147.34,1216.43 1147.34,1206.95 1173.75,1206.95 1173.75,1201.79  "/>
            <polyline points="1241.5,1201.79 1209.92,1201.79 1209.92,1236.24 1215.09,1236.24 1215.09,1221.6 1236.34,1221.6 1236.34,1216.43    1215.09,1216.43 1215.09,1206.95 1241.5,1206.95 1241.5,1201.79  "/>
            <path d="M1259.21,1218.77c-0.29,0-0.53-0.1-0.74-0.31c-0.21-0.21-0.31-0.45-0.31-0.74v-9.66c0-0.29,0.1-0.53,0.31-0.74   c0.21-0.21,0.45-0.31,0.74-0.31h22.01c0.29,0,0.53,0.1,0.74,0.31c0.21,0.21,0.31,0.45,0.31,0.74v9.66c0,0.29-0.1,0.53-0.31,0.74   c-0.21,0.21-0.45,0.31-0.74,0.31H1259.21 M1281.21,1201.83h-28.23v34.4h5.17v-12.39c0.45,0.06,0.8,0.1,1.05,0.1h11.77l10.33,12.3   h6.12v-0.72l-9.71-11.58h3.49c1.72,0,3.19-0.61,4.4-1.84c1.21-1.23,1.82-2.69,1.82-4.38v-9.66c0-1.69-0.61-3.15-1.82-4.38   C1284.4,1202.45,1282.94,1201.83,1281.21,1201.83"/>
            <polyline points="1332.79,1201.79 1301.21,1201.79 1301.21,1236.24 1332.79,1236.24 1332.79,1231.07 1306.38,1231.07    1306.38,1221.6 1327.63,1221.6 1327.63,1216.43 1306.38,1216.43 1306.38,1206.95 1332.79,1206.95 1332.79,1201.79  "/>
            <polyline points="1378.06,1201.79 1346.48,1201.79 1346.48,1236.24 1378.06,1236.24 1378.06,1231.07 1351.64,1231.07    1351.64,1221.6 1372.89,1221.6 1372.89,1216.43 1351.64,1216.43 1351.64,1206.95 1378.06,1206.95 1378.06,1201.79  "/>
            <polyline points="1396.72,1231.07 1391.55,1231.07 1391.55,1236.24 1396.72,1236.24 1396.72,1231.07  "/>
        </g>
        </svg>
            <br><br>
        </el-col>
        <el-col :span="7"></el-col>
    </el-row>
    <el-row>
        <el-col :span="2"></el-col>
        <el-col :span="20">
            <h3 class="detailTitleMobile" v-if="isMobile()">Empowering the dreamers of today,<br>to create a better tomorrow for everyone.</h3>
            <h3 class="detailTitle" v-if="!isMobile()">Empowering the dreamers of today,<br>to create a better tomorrow for everyone.</h3>
            <br>
            <p class="detailParaMobile" v-if="isMobile()">
                Me3 is a utility-rich NFT collection of 3,030 limited PFPs designed to help builders and vagabonds come together in harmony and acceptance.<br><br>
                Regardless of your quirks, tastes, and individual differences, ME3 is a safe space where you are always welcome to stay.<br><br>
                It’s a place for dreamers to learn, grow, and flourish in your life’s personal journey.<br><br>
                Your Me3 NFT serves as your digital identity in the metaverse. It’s an extension of your being. <br><br>
                You are the person you envision in your mind, and we are the community that supports you in making your ambitions come true.<br><br>
            </p>
            <p class="detailPara" v-if="!isMobile()">
                Me3 is a utility-rich NFT collection of 3,030 limited PFPs designed to help builders and vagabonds come together in harmony and acceptance.<br><br>
                Regardless of your quirks, tastes, and individual differences, ME3 is a safe space where you are always welcome to stay.<br><br>
                It’s a place for dreamers to learn, grow, and flourish in your life’s personal journey.<br><br>
                Your Me3 NFT serves as your digital identity in the metaverse. It’s an extension of your being. <br><br>
                You are the person you envision in your mind, and we are the community that supports you in making your ambitions come true.<br><br>
            </p>
        </el-col>
        <el-col :span="2"></el-col>
    </el-row>
    
</div>
</template>

<script>
export default {
    methods: {
            isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style>
.logoMobile
{
    width: 20vh;
    margin-top: 5vw;
    filter: invert(100%) sepia(1%) saturate(7472%) hue-rotate(117deg) brightness(109%) contrast(100%)
}
.logo
{
    width: 35vh;
    margin-top: 5vw;
    filter: invert(100%) sepia(1%) saturate(7472%) hue-rotate(117deg) brightness(109%) contrast(100%)
}
.detailTitleMobile
{
    font-size: 3vh;
    font-family: "Orbitron", sans-serif !important;
    color: white !important;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    font-weight: 700;
    text-transform: uppercase;
}
.detailTitle
{
    font-size: 3.5vh;
    font-family: "Orbitron", sans-serif !important;
    color: white !important;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    font-weight: 700;
    text-transform: uppercase;
}
.detailParaMobile
{
    color: white;
    font-size: 3vh;
    margin-bottom: 10vw;
}
.detailPara
{
    color: white;
    font-size: 2.5vh;
    margin-bottom: 10vw;
}
</style>